.athlete #section1 {
    padding-inline: 1rem;
    .v-card {
        height: 18rem;
        overflow: visible;
        margin-bottom: 3rem;
        position: relative;
        .v-avatar {
            width: 100%;
            height: 100%;
        }
    }
    .edit-profile {
        position: absolute;
        bottom: -3.2rem;
        display: flex;
        justify-content: space-between;
        width: calc(100% - 3rem);
        align-items: center;
        margin-inline: 1.5rem;
        .button-wrap {
            height: max-content;
            margin-top: 4rem;
            display: flex;
            gap: 1rem;
        }
    }
    .profile-pic {
        width: 9rem;
        height: 11rem;
    }
    .section-wrapper {
        display: flex;
        gap: 1rem;
        .section-left {
            width: 30%;
            margin-left: .5rem;
        }
        .section-right {
            width: 70%;
        }
    }
    .card-wrapper {
        display: flex;
        gap: 2rem;
        margin: 2rem 1rem;
        .v-card {
            width: 50%;
        }
    }
    .group_events {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-block: 2rem 1rem;
    }
    .list-category {
        display: flex;
        gap: 1rem;
    }
    .section-left {
        .v-list-item {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 1rem;
            &.name {
                font-size: 1.2rem;
                margin: 0;
                justify-content: center;
            }
            &.country-name {
                font-size: .8rem;
                margin: 0;
            }
        }
    }
    .group-item {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
    .bold {
        font-weight: 700;
    }
    .text-icon-wrap {
        display: flex;
        align-items: center;
    }
    .table-wrapper {
        &:not(:last-child) {
            margin-bottom: 3rem;
        }
    }
    .check-cirlce {
        color: var(--v-icon-color, #3daf3d);
    }
    .checked {
        border-color: #3daf3d;
        background: #e4efe4;
    }
    .athlete-table {
        .table-row {
            display: grid;
            grid-template-columns: 3fr 1.5fr 1.5fr 1.5fr;
        }
    }
    .badge {
        background: #82ade5;
        color: #6644ff;
    }
    .icon-wrapper {
        width: 2.5rem;
        background: var(--v-card-background-color, var(--theme--form--field--input--background-subdued));
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }
    .v-notice {
        .v-button {
            justify-content: flex-end;
            width: max-content;
            flex-grow: 1;
        }
    }
    .view-btn {
        a {
            background-color: var(--white, var(--theme--primary));
        }
    }
    .dropdown {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
        &-item {
            display: flex;
            cursor: pointer;
            &.active {
                + .items {
                    display: block;
                }
            }
        }
    }
    .items {
        display: none;
        list-style: none;
        text-align: left;
        display: block;
        box-shadow: 0rem 0.1rem 2rem .2rem black;
        max-height: 90px;
        border-radius: .5rem;
        padding-inline: 9px;
        margin-bottom: 1rem;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .down-arrow {
        rotate: 90deg;
    }
}

@media screen and (max-width: 1024px) {
    .content-wrapper .main-section{
        &.athlete{
            grid-template-columns: 100%;
        }
    }
    .athlete #section1{
        padding-inline: 0;
        .edit-profile {
            align-items: flex-end;
            .button-wrap {
                flex-direction: column;
                margin-top: 0;
            }
        }
        .section-wrapper{
            flex-direction: column;
            .section-left {
                width: 100%;
                margin-left: 0;
            }
            .section-right {
                width: 100%;
            }
        }
    }
}